import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';

const ProcessSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        EXPERIENCE {
          companyImage
          companyName
          start
          end
          location
          role
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} as="section" id="process_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="About" />
         <Text
            {...secDescription}
            content="Seasoned IT professional with 8+ years of experience across the entire IT software scope in Enterprise. Focused on Android application development for 5+ years and Unix­based server operating system for 2 years. Have strong knowledge of object­oriented design, data structures, algorithm design, problem solving and complexity analysis."
          />          
        </Box>
      </Container>
    </Box>
  );
};

ProcessSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object
};

ProcessSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '100px'],
    mb: ['15px', '18px', '18px', '40px', '40px'],
  },
  secTitleWrapper: {

  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '40px', '40px'],
    textAlign: 'left',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    pl: '32px',
    pr: '32px'
  },
};

export default ProcessSection;
